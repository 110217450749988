
@import "./Styles/constant";
@import "./Styles/variables";
@import "./Styles/loading-spinner.scss";

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
// // font-family: "Open Sans", sans-serif;

// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// // font-family: "Poppins", sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Acme&family=Nunito:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Acme&family=Nunito:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // font-family: 'Nunito', sans-serif;
    font-family: 'Open Sans', sans-serif;

}
// html {
//   scroll-behavior: smooth;
// }

.scroll-to-top-container {
    position: fixed;
    left: 0;
    bottom: 20px; /* Adjust as needed */
    z-index: 1000; /* Ensure it appears above other content */
  }


// @media screen and (max-width: 769px) {
//     .app{
//         // width: 100vh;
//         // height: 100vh;
      
       
//     }
// }



// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }



// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
